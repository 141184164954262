import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { get, capitalize } from 'lodash';
import { matchPath, Link } from 'react-router-dom';
import { Form, Grid, Header, Icon } from 'semantic-ui-react';
import SecondaryMenu from '../../../theme/layout/SecondaryMenu';
import NotFound from '../../shared/NotFound';
import LockUnlockOffering from '../admin/offerings/components/LockUnlockOffering';

const isMobile = document.documentElement.clientWidth < 768;
const overrideContainerClass = ['account-details/:accountType/transactions'];
@inject('uiStore', 'navStore', 'userStore', 'userDetailsStore', 'offeringsStore')
@observer
class PrivateLayout extends Component {
  render() {
    const { location, navStore, offeringsStore } = this.props;
    const pageMeta = navStore.navMeta;
    const { isInvestor, isIssuer } = this.props.userStore;
    const { match } = this.props;
    const { offer } = offeringsStore;
    const {
      processingAccounts,
      partialAccounts,
    } = this.props.userDetailsStore.signupStatus;
    const splittedUrl = match.url.split('/');
    const accType = splittedUrl.pop();
    const isAccProcessing = processingAccounts.includes(accType);
    const isAccPartial = partialAccounts.includes(accType);
    const splittedPathname = location.pathname.split('/');
    const issuerStep = splittedPathname.includes('campaign') ? 'Dashboard' : splittedPathname.includes('overview') ? 'General'
    : get(offer, 'stage') === 'CREATION' ? splittedPathname.slice(4)[0] : splittedPathname.pop();
    const isIssuerOffering = matchPath(location.pathname, { path: '/dashboard/offering/*' }) && isIssuer;
    if (!pageMeta) {
      return <NotFound />;
    }
    return (
      <>
        <div className={`${isIssuer && !isMobile && pageMeta.title === 'Home' ? '' : 'page-header-section'} ${isIssuer && accType !== 'dashboard' ? 'pb-0' : ''} ${isInvestor ? 'investor' : ''}`}>
          <Grid columns="equal" stackable>
            <Grid.Row className={`${isIssuer ? 'pb-0' : ''}`}>
              <Grid.Column verticalAlign="middle">
                {!this.props.hideHeader
                  && (
                    <Header Header as={isInvestor ? (isMobile ? 'h5' : 'h3') : 'h1'}>
                      {this.props.forceTitle || pageMeta.heading || (isIssuer && !isMobile && pageMeta.title === 'Home' ? '' : pageMeta.title)}
                      {isIssuerOffering && issuerStep
                      && <span> | {capitalize(issuerStep)}</span>}
                    </Header>
                  )}
              </Grid.Column>
              {this.props.P4
                && <Grid.Column only="large screen" width={this.props.buttonWidth ? this.props.buttonWidth : 3} floated={!isMobile ? 'right' : ''} textAlign={!isMobile ? 'right' : 'center'}>{this.props.P4}</Grid.Column>
              }
            </Grid.Row>
          </Grid>
        </div>
        {((pageMeta.subPanel === 1 || this.props.subNav) && !this.props.hideSubNav && !isIssuer && !(isInvestor && (isAccPartial || isAccProcessing)))
          && <SecondaryMenu addon={this.props.subNavAddon} noinvert refMatch={this.props.refMatch} match={this.props.match} attached="bottom" className={`${isInvestor ? 'investor' : ''} secondary-menu`} navItems={pageMeta.subNavigations} stepsStatus={this.props.appStepsStatus} rightLabel={this.props.rightLabel} />
        }
        {this.props.P1
          && (
            <div className="search-filters">
              <Form>
                <Grid stackable>
                  <Grid.Row>
                    {this.props.P1}
                  </Grid.Row>
                </Grid>
              </Form>
            </div>
          )
        }
        {this.props.P2}
        {this.props.P3}
        {this.props.P5}
        <div className={`${(overrideContainerClass.find(item => matchPath(location.pathname, { path: `/dashboard/${item}` }))) ? '' : 'content-spacer'}`}>
          {isIssuerOffering && (
            <Header>
              <Header.Subheader>
                <Link target="_blank" to={`/offerings/${offer.stage === 'CREATION' ? 'preview/' : ''}${offer.offeringSlug}`}>
                  <Icon className="ns-view" />
                </Link>
                <Link target="_blank" to={`/offerings/${offer.stage === 'CREATION' ? 'preview/' : ''}${offer.offeringSlug}`} className="neutral-text green-hover no-decoration">
                  Preview
                </Link>
                {get(offer, 'stage') === 'CREATION' && <LockUnlockOffering />}
              </Header.Subheader>
            </Header>
          )}
          {this.props.children}
        </div>
      </>
    );
  }
}

export default PrivateLayout;
