import React from 'react';
import { Link } from 'react-router-dom';
import { Header, Container, Button, Grid, Item, Divider, Responsive } from 'semantic-ui-react';
import NSImage from '../../../shared/NSImage';

const highlights = [
  {
    title: 'Pre-vetted opportunities',
    icon: 'icons/compass.svg',
    meta: (
      <>
        Historically, only 3% of applicants are approved and launch an offering on NextSeed.<sup>1</sup>
      </>
    ),
  },
  // {
  //   title: 'Businesses you understand',
  //   icon: 'icons/checked.svg',
  //   meta: `Investments in growing businesses across industries. Access the right
  //     ones for you.`,
  // },
  // {
  //   title: 'Flexible amounts',
  //   icon: 'icons/graph.svg',
  //   meta: 'Never invest more than you can risk. Investments may start as low as $100.',
  // },
  {
    title: 'Exclusive deals',
    icon: 'icons/deals.svg',
    meta: `Opportunities that were once privately reserved for wealthy
    and well-connected investors.`,
  },
  {
    title: 'Impactful investments',
    icon: 'icons/heart.svg',
    meta: (
      <>
        Invest in job-creation, driven by entrepreneurs in communities across the country.<br />
        Plus, over 75% of the dollars invested have gone to companies founded by women and people of color<sup>2</sup>
      </>
    ),
  },
  {
    title: 'Returns processed for you',
    icon: 'icons/return.svg',
    meta: (
      <>
        No need to chase payments. Over $5 million facilitated to investors through the platform to date<sup>3</sup>.
      </>
    ),
  },
];

const HowItWorksSummary = ({ covidBanner, isUserLoggedIn, isMobile, uptoTablet }) => (
  <>
  <section>
    <Container className={isMobile ? 'mb-20' : ''} textAlign={isMobile ? 'left' : 'center'}>
      <Header as="h2" className={uptoTablet ? 'mb-10' : 'mb-50'}>Expand your portfolio with<br /> exclusive alternative investments</Header>
      <Divider as={Container} hidden />
      <Item.Group className="dl-horizontal">
        {highlights.map(h => (
          <Item>
            <Item.Content>
              <Item.Header>{h.title}</Item.Header>
              <Item.Meta className="neutral-text">{h.meta}</Item.Meta>
            </Item.Content>
          </Item>
        ))}
        {/* {!uptoTablet && <Divider vertical />} */}
      </Item.Group>
      <div className="center-align mb-50">
        { !isUserLoggedIn
          && <Button fluid={isMobile} className={!isMobile ? 'mt-50' : 'mt-40'} as={Link} to="/register-investor" primary>Create a  Free Account</Button>
        }
      </div>
      <p className={`${isMobile ? '' : 'center-align'} note`}>
      <sup>1</sup>This calculates the percent of businesses that began the application process, passed
      NextSeed&apos;s objective diligence criteria, and launched an offering on the platform since NextSeed&apos;s inception.
      </p>
      <p className={`${isMobile ? '' : 'center-align'} note`}><sup>2 3</sup> Data reflects figures from offerings conducted by NextSeed TX LLC, NextSeed US LLC and NextSeed Securities, LLC as of July 2020</p>
    </Container>
  </section>
  {!uptoTablet && <Divider as={Container} hidden />}
  <section key="covidBanner" className={isMobile ? 'bg-offwhite' : 'mt-50 mb-50 bg-offwhite'}>
    {covidBanner}
  </section>
  <Divider as={Container} hidden />
  <section>
    <Container className={isMobile ? '' : 'mt-50 mb-50'}>
      <Grid>
        <Grid.Row>
          <Grid.Column widescreen={8} computer={8} tablet={16} mobile={16}>
            <Header as="h2" className={uptoTablet ? 'mb-30' : 'mb-40'}>We’ve built an alternative<Responsive minWidth={768} as="br" /> investment platform<Responsive minWidth={768} as="br" /> from the ground up.</Header>
            <p className={`${uptoTablet ? 'mb-14' : 'mb-50'} neutral-text`}>
            Browse highly vetted companies and invest <Responsive minWidth={768} as="br" />
            in just a few clicks, on any device.
            </p>
            {!isUserLoggedIn && !isMobile
              && (
                <Button as={Link} to="/register-investor" primary className="mb-30">Create a  Free Account</Button>
              )
            }
            {isMobile
             && <NSImage path="phones-mockup.png" className="mb-20" />
            }
          </Grid.Column>
          <Grid.Column widescreen={8} computer={8} tablet={16} mobile={16}>
            {!isMobile
             && <NSImage path="phones-mockup.png" className="mb-20" />
            }
            {!isUserLoggedIn && isMobile
              && (
                <Button fluid as={Link} to="/register-investor" primary>Create a  Free Account</Button>
              )
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  </section>
  <Divider as={Container} fitted />
  </>
);

export default HowItWorksSummary;
