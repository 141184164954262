/* eslint-disable import/no-extraneous-dependencies  */
import React from 'react';
import $ from 'jquery';
import { inject, observer } from 'mobx-react';
import { get } from 'lodash';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
// image
import 'froala-editor/js/plugins/image.min';
import 'froala-editor/css/plugins/image.min.css';
// draggable
import 'froala-editor/js/plugins/draggable.min';
import 'froala-editor/css/plugins/draggable.min.css';
// font_size
import 'froala-editor/js/plugins/font_size.min';
// font_family
import 'froala-editor/js/plugins/font_family.min';
// link
import 'froala-editor/js/plugins/link.min';
// lists
import 'froala-editor/js/plugins/lists.min';
// paragraph_style
import 'froala-editor/js/plugins/paragraph_style.min';
// table
import 'froala-editor/js/plugins/table.min';
// url
import 'froala-editor/js/plugins/url.min';
// colors
import 'froala-editor/js/plugins/colors.min';
// code_view
import 'froala-editor/js/plugins/code_view.min';
// align
import 'froala-editor/js/plugins/align.min';
// char_counter
import 'froala-editor/js/plugins/char_counter.min';
import 'froala-editor/css/plugins/char_counter.min.css';
// code_beautifier
import 'froala-editor/js/plugins/code_beautifier.min';
// embedly
import 'froala-editor/js/third_party/embedly.min';
import 'froala-editor/css/third_party/embedly.min.css';
// emoticons
import 'froala-editor/js/plugins/emoticons.min';
import 'froala-editor/css/plugins/emoticons.min.css';
// entities
import 'froala-editor/js/plugins/entities.min';
// // font_awesome
import 'froala-editor/js/third_party/font_awesome.min';
// fullscreen
import 'froala-editor/js/plugins/fullscreen.min';
import 'froala-editor/css/plugins/fullscreen.min.css';
// help
import 'froala-editor/js/plugins/help.min';
import 'froala-editor/css/plugins/help.min.css';
// inline_class
import 'froala-editor/js/plugins/inline_class.min';
// inline_style
import 'froala-editor/js/plugins/inline_style.min';
// line_breaker
import 'froala-editor/js/plugins/line_breaker.min';
import 'froala-editor/css/plugins/line_breaker.min.css';
// line_height
import 'froala-editor/js/plugins/line_height.min';
// paragraph_format
import 'froala-editor/js/plugins/paragraph_format.min';
// print
import 'froala-editor/js/plugins/print.min';
// quick_insert
import 'froala-editor/js/plugins/quick_insert.min';
import 'froala-editor/css/plugins/quick_insert.min.css';
// quote
import 'froala-editor/js/plugins/quote.min';
// special_characters
import 'froala-editor/js/plugins/special_characters.min';
import 'froala-editor/css/plugins/special_characters.min.css';
// video
import 'froala-editor/js/plugins/video.min';
import 'froala-editor/css/plugins/video.min.css';
// word_paste
// import 'froala-editor/js/plugins/word_paste.min';

import { Confirm, Dimmer } from 'semantic-ui-react';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import FroalaEditor from 'froala-editor';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { fileUpload } from '../../services/actions';
import { FROALA_EDITOR_LICENSE } from '../../constants/common';
import { UPLOADS_CONFIG } from '../../constants/aws';
import ShortCodeInforModal from './ShortCodeInfo';
import Helper from '../../helper/utility';
import { InlineLoader } from '../../theme/shared';

window.$ = $;
window.jQuery = $;

@inject('uiStore')
@observer
export default class HtmlEditor extends React.Component {
  state = {
    showModal: false,
    showConfirmModal: false,
    errorMsg: '',
  }

  constructor(props) {
    super(props);
    FroalaEditor.DefineIcon('popup-btn', { NAME: 'info', SVG_KEY: 'help' });
    FroalaEditor.RegisterCommand('popup-btn', {
      title: 'Show Short Codes Information',
      icon: 'help',
      undo: false,
      focus: false,
      refreshAfterCallback: false,
      callback: this.toggleModal,
    });
  }

  updateState = (field, val) => {
    this.setState({ [field]: val });
  }

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  }

  getConfig = (keyStart, overrides, thisParent) => {
    const config = {
      app: 'froala',
      placeholderText: 'Enter here..',
      toolbarButtons: {
        moreText: {
          buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],
        },
        moreParagraph: {
          buttons: ['formatOL', 'formatUL', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
          align: 'left',
          buttonsVisible: 2,
        },
        moreRich: {
          buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'fontAwesome', 'emoticons', 'specialCharacters', 'embedly', 'insertHR'],
          align: 'left',
          buttonsVisible: 3,
        },
        moreMisc: {
          buttons: ['undo', 'redo', 'fullscreen', 'getPDF', 'print', 'selectAll', 'html', 'popup-btn'],
          align: 'left',
          buttonsVisible: 2,
        },
      },
      lineBreakerTags: ['table', 'hr'],
      editorClass: 'html-editor',
      key: FROALA_EDITOR_LICENSE,
      heightMin: 244,
      heightMax: '70vh',
      events: {
        'image.inserted': function (image) {
          if (image.length && get(image[0], 'dataset.dataFroalaUploaded')) {
            thisParent.props.uiStore.setFieldvalue('htmlEditorImageLoading', false);
            this.edit.on();
          }
        },
        'image.loaded': function () {
          const frSelected = document.querySelector('.fr-image-resizer.fr-active');
          if (frSelected) {
            frSelected.classList.remove('fr-active');
          }
          this.popups.hideAll();
          if (thisParent.props.uiStore.htmlEditorImageLoading) {
            this.edit.off();
          }
        },
        'image.beforeUpload': function (images) {
          thisParent.props.uiStore.setFieldvalue('htmlEditorImageLoading', true);
          if (images.length) {
            const fileName = get(images, '[0].name');
            const file = get(images, '[0]');
            const fileObj = {
              obj: file,
              type: file.type,
              name: fileName,
            };
            const fileExt = (fileName && typeof fileName === 'string') ? get(fileName.split('.'), `[${fileName.split('.').length - 1}]`) : '';
            const validate = Helper.validateImageExtension(fileExt, true);
            if (!validate.isInvalid) {
              fileUpload.uploadToS3(fileObj, thisParent.props.imageUploadPath || 'RichTextEditor', false, true).then((res) => {
                setTimeout(() => {
                  if (this && this.image && this.image.get()) {
                    const imgUrl = `https://${UPLOADS_CONFIG.bucket}/${res}`;
                    this.image.insert(imgUrl, true, { 'data-froala-uploaded': true }, this.image.get());
                  } else {
                    thisParent.props.uiStore.setFieldvalue('htmlEditorImageLoading', false);
                    this.edit.on();
                  }
                  return false;
                }, 1000);
              }).catch((error) => {
                window.logger(error);
                thisParent.props.uiStore.setFieldvalue('htmlEditorImageLoading', false);
                this.edit.on();
                return false;
              });
            } else {
              thisParent.props.uiStore.setFieldvalue('htmlEditorImageLoading', false);
              this.edit.on();
              thisParent.updateState('errorMsg', validate.errorMsg);
              thisParent.updateState('showConfirmModal', true);
            }
          }
        },
        'image.error': function (error) {
          thisParent.props.uiStore.setFieldvalue('htmlEditorImageLoading', false);
          this.edit.on();
          window.logger(error);
        },
      },
    };
    return { ...config, ...overrides };
  };

  handleModelChange = (content) => {
    this.props.changed(this.props.name, content, this.props.form, this.props.index);
  }

  render() {
    const { keyStart, readOnly, tag, noDivWrap, uiStore } = this.props;
    if (readOnly) {
    const FroalaView = <FroalaEditorView tag={tag} model={this.props.content} />;
    return noDivWrap ? FroalaView : <div className="parsed-data overflow-wrap">{FroalaView}</div>;
    }
    const { htmlEditorImageLoading } = uiStore;
    return (
      <div className="position-relative">
        <FroalaEditorComponent
          tag="textarea"
          model={this.props.content}
          config={this.getConfig(keyStart, this.props.overrides, this)}
          onModelChange={this.handleModelChange}
        />
        <Dimmer style={{ 'pointer-events': 'none' }} className="truly" inverted active={htmlEditorImageLoading}>
          <InlineLoader nsLogo />
        </Dimmer>
        <ShortCodeInforModal toggleModal={this.toggleModal} showModal={this.state.showModal} />
        <Confirm
          header="Warning"
          content={this.state.errorMsg}
          open={this.state.showConfirmModal}
          onConfirm={() => this.updateState('showConfirmModal', false)}
          onCancel={() => this.updateState('showConfirmModal', false)}
          size="mini"
          className="deletion"
        />
      </div>
    );
  }
}
