import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';

const TopBanner = withRouter(props => (
    <div className={(props.leftMenu) ? 'sticky-message hide' : 'sticky-message'}>
      <div>
        <span className="white-highlight"><b>New!</b> {' '}</span>
        {props.isMobile
        ? <span className="regular-text">Collaboration Capital’s ESG Managed Portfolios.{' '}</span>
        : <span className="regular-text">Announcing a brand new way to invest with purpose -{' '}</span>
        }
        {!props.isMobile && <b>Collaboration Capital’s ESG Managed Portfolios.</b>}
        <Link onClick={props.toggle} rel="noopener noreferrer" to="/insights/esg-managed-portfolios" className="white-highlight">
          {' '}<b>Learn More</b><Icon size="small" className="ns-chevron-right" />
        </Link>
      </div>
      <Button
        onClick={props.toggle}
        className="close-button"
        circular
        size="large"
        icon={{ className: 'ns-close-light' }}
      />
    </div>
));

export default TopBanner;
