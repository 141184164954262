import moment from 'moment';
import { observable, action, computed, toJS } from 'mobx';
// import graphql from 'mobx-apollo';
import { isEmpty, find, omit, get, pick, set } from 'lodash';
import { DataFormatter, FormValidator } from '../../../../helper';
import {
  IRA_ACC_TYPES,
  PRIMARY_BENEFICIARY,
  CONTINGENT_BENEFICIARY,
  DESIGNATE_BENEFICIARY,
  IRA_FIN_INFO,
  IRA_IDENTITY,
  IRA_FUNDING,
  FILE_UPLOAD_STEPS,
  SPOUSAL_CONSENT,
  CUSTODIAN,
  RMD,
  ASSETS_LIST,
  ASSETS_LIQUIDATION,
  US_STATES,
  IRA_STATEMENT_UPLOAD,
} from '../../../../constants/account';
import AccCreationHelper from '../../../../modules/private/investor/accountSetup/containers/accountCreation/helper';
import { uiStore, bankAccountStore, userDetailsStore, investmentLimitStore, accountStore } from '../../index';
import { upsertInvestorAccount, submitInvestorAccount, generateIraAccountApplication } from '../../queries/account';
import { validationActions, fileUpload } from '../../../actions';
import { GqlClient as client } from '../../../../api/gqlApi';
import Helper from '../../../../helper/utility';

const isMobile = document.documentElement.clientWidth < 768;

class IraAccountStore {
  @observable FIN_INFO_FRM = FormValidator.prepareFormObject(IRA_FIN_INFO);

  @observable CONTINGENT_BENEFICIARY_FRM = FormValidator.prepareFormObject(CONTINGENT_BENEFICIARY);

  @observable TEMP_FRM = FormValidator.prepareFormObject(PRIMARY_BENEFICIARY);

  @observable IDENTITY_FRM = FormValidator.prepareFormObject(IRA_IDENTITY);

  @observable DESIGNATE_PRIMARY_BENEFICIARY_FRM = FormValidator.prepareFormObject(DESIGNATE_BENEFICIARY);

  @observable DESIGNATE_CONTINGENT_BENEFICIARY_FRM = FormValidator.prepareFormObject(DESIGNATE_BENEFICIARY);

  @observable PRIMARY_BENEFICIARY_FRM = FormValidator.prepareFormObject(PRIMARY_BENEFICIARY);

  @observable ACC_TYPES_FRM = FormValidator.prepareFormObject(IRA_ACC_TYPES);

  @observable FUNDING_FRM = FormValidator.prepareFormObject(IRA_FUNDING);

  @observable SPOUSAL_CONSENT_FRM = FormValidator.prepareFormObject(SPOUSAL_CONSENT);

  @observable CUSTODIAN_FRM = FormValidator.prepareFormObject(CUSTODIAN);

  @observable RMD_FRM = FormValidator.prepareFormObject(RMD);

  @observable ASSETS_FRM = FormValidator.prepareFormObject(ASSETS_LIST);

  @observable ASSETS_LIQUIDATION_FRM = FormValidator.prepareFormObject(ASSETS_LIQUIDATION);

  @observable IRA_STATEMENT_UPLOAD_FRM = FormValidator.prepareFormObject(IRA_STATEMENT_UPLOAD);

  @observable nestedSteps = [0];

  @observable beneficiaryIndex = 0;

  @observable editBeneficiary = false;

  @observable isBeneficiariesList = false

  @observable isCancelEnabled = false;

  @observable beneficiaryType = '';

  @observable iraAccountId = null;

  @observable showProcessingModal = false;

  @observable isFormSubmitted = false;

  @observable apiCall = false;

@observable showConfirmModal = false;

  @observable retry = 0;

  @observable stepToBeRendered = 0;

  @observable accountNotSet = '';

  @observable beneficiaryDetails = {
    primaryStatus: '',
    contingentStatus: '',
  };

  @observable signIraApplicationURL = '';

  @observable isIraValid = false;

  @observable iraType = '';

  @action
  setStepToBeRendered = (step) => {
    this.stepToBeRendered = step;
  }

  @action
  nextStepToBeRendered = (currentStep) => {
    this.setStepToBeRendered(currentStep.stepToBeRendered);
  }

  @action
  setAccountNotSet(step) {
    this.accountNotSet = step;
  }

  @action
  formChange = (e, result, form, type, checked = undefined) => {
    const formName = Array.isArray(form) ? form[0] : form;
    if (Array.isArray(form)) {
      this[formName] = FormValidator.onArrayFieldChange(
        this[formName],
        FormValidator.pullValues(e, result),
        form[1],
        form[2],
        type,
        checked,
      );
    } else {
      this[formName] = FormValidator.onChange(this[formName], FormValidator.pullValues(e, result), type, true, checked);
    }
    this.currTime = +new Date();
  };

  @action
  setAddressFieldsForArray = (place, form) => {
    const formName = Array.isArray(form) ? form[0] : form;
    FormValidator.setAddressFieldsIndex(place, this[formName], formName, form[1], form[2], true, US_STATES);
  }

  @action
  addMore = (form, key, count = 1) => {
    this[form] = FormValidator.addMoreRecordToSubSection(this[form], key, count, true);
  }

  @action
  removeOne = (form, arrayName, index, e = undefined) => {
    if (e) {
      e.preventDefault();
    }
    this[form].fields[arrayName].splice(index, 1);
  }

  @action
  maskChange = (values, field, form, type) => {
    const formName = Array.isArray(form) ? form[0] : form;
    const fieldValue = type ? values[`${type}Value`] : values.value; // floatValue
    if (Array.isArray(form)) {
      this[formName] = FormValidator.onArrayFieldChange(
        this[formName],
        { name: field, value: fieldValue },
        form[1],
        form[2],
      );
    } else {
      this[formName] = FormValidator.onChange(this[formName], { name: field, value: fieldValue === undefined ? null : fieldValue });
    }
    this.currTime = +new Date();
  };

  @action
  finInfoChange = (values, field) => {
    this.FIN_INFO_FRM = FormValidator.onChange(
      this.FIN_INFO_FRM,
      { name: field, value: values.floatValue },
    );
    const investmentLimit = investmentLimitStore.getInvestmentLimit({
      annualIncome: typeof this.FIN_INFO_FRM.fields.income.value === 'string' ? parseFloat(this.FIN_INFO_FRM.fields.income.value) : this.FIN_INFO_FRM.fields.income.value,
      netWorth: typeof this.FIN_INFO_FRM.fields.netWorth.value === 'string' ? parseFloat(this.FIN_INFO_FRM.fields.netWorth.value) : this.FIN_INFO_FRM.fields.netWorth.value,
    });
    this.FIN_INFO_FRM = FormValidator.onChange(
      this.FIN_INFO_FRM,
      { name: 'investmentLimit', value: investmentLimit },
    );
  }

  @computed
  get isValidIraForm() {
    if (this.FUNDING_FRM.fields.fundingType.value === 0) {
      return this.FIN_INFO_FRM.meta.isValid && this.ACC_TYPES_FRM.meta.isValid
        && this.FUNDING_FRM.meta.isValid && this.IDENTITY_FRM.meta.isValid && bankAccountStore.formIraAddFunds.meta.isValid
        && (bankAccountStore.formLinkBankManually.meta.isValid || bankAccountStore.isAccountPresent);
    }
    return this.FIN_INFO_FRM.meta.isValid && this.ACC_TYPES_FRM.meta.isValid
      && this.FUNDING_FRM.meta.isValid && this.IDENTITY_FRM.meta.isValid;
  }

  @computed
  get isValidIraDocusign() {
    const accountDetails = find(userDetailsStore.currentUser.data.user.roles, { name: 'ira' });
    this.isIraValid = (get(accountDetails, 'details.docuSign.envelope.status') === 'completed');
    return this.isIraValid;
  }

  @action
  isDocuSignEnvelopePresent = () => {
    const accountDetails = find(userDetailsStore.currentUser.data.user.roles, { name: 'ira' }) || '';
    this.isEnvelopePresent = !!get(accountDetails, 'details.docuSign.envelope.envelopeId');
    return this.isEnvelopePresent;
  }

  @computed
  get accountType() {
    const { values, value } = this.ACC_TYPES_FRM.fields.iraAccountType;
    return find(values, { value });
  }

  @computed
  get spousalConsent() {
    const { values, value } = this.SPOUSAL_CONSENT_FRM.fields.spousalConsent;
    return find(values, { value });
  }

  @computed
  get custodian() {
    const { values, value } = this.CUSTODIAN_FRM.fields.values;
    return find(values, { value });
  }

  @computed
  get currentNestedStep() {
    return this.nestedSteps.slice(-1)[0] || 0;
  }

  @computed
  get fundingOption() {
    const { values, value } = this.FUNDING_FRM.fields.fundingType;
    return find(values, { value });
  }

  @computed
  get isAssetValid() {
    return this.ASSETS_FRM.meta.isValid && this.ASSETS_LIQUIDATION_FRM.meta.isValid;
  }

  @computed
  get custodianVariables() {
    const { fields } = this.CUSTODIAN_FRM;
    const selectedState = find(US_STATES, { value: fields.state.value });
    const planTypeValue = fields.planType.value === 'OTHER' ? fields.other.value : fields.planType.value;
    const custodianVariables = {
      name: fields.name.value || '',
      accntNumber: fields.accntNumber.value || '',
      phone: fields.phone.value || '',
      planType: planTypeValue || '',
      address: {
        street: fields.street.value || '',
        city: fields.city.value || '',
        state: selectedState ? selectedState.key || '' : '',
        zipCode: fields.zipCode.value || '',
      },
    };
    return custodianVariables;
  }

  @computed
  get assetsLiquidationVars() {
    const assetsArrFields = this.ASSETS_FRM.fields;
    const assetsVar = FormValidator.evaluateFormData(assetsArrFields).assets;
    const assetsFields = FormValidator.evaluateFormData(this.ASSETS_LIQUIDATION_FRM.fields);
    const assetsLiquidationVariables = {
      assets: assetsVar,
      ...assetsFields,
    };
    return assetsLiquidationVariables;
  }

  @computed
  get accountAttributes() {
    /* eslint-disable camelcase */
    const payload = {};
    payload.limits = {};
    const limitValues = FormValidator.ExtractValues(this.FIN_INFO_FRM.fields);
    payload.limits = omit(limitValues, ['investmentLimit']);
    payload.identityDoc = {};
    payload.identityDoc.fileId = this.IDENTITY_FRM.fields.identityDoc.fileId;
    payload.identityDoc.fileName = this.IDENTITY_FRM.fields.identityDoc.value;
    payload.spousalConsent = this.spousalConsent.value;
    payload.iraAccountType = this.accountType.rawValue;
    payload.assetsLiquidation = this.assetsLiquidationVars;
    payload.custodian = this.custodianVariables;
    payload.uploads = {};
    payload.uploads.fileId = this.IRA_STATEMENT_UPLOAD_FRM.fields.uploads.fileId;
    payload.uploads.fileName = this.IRA_STATEMENT_UPLOAD_FRM.fields.uploads.value;
    payload.uploads.type = this.IRA_STATEMENT_UPLOAD_FRM.fields.uploads.type;
    payload.fundingType = this.fundingOption.rawValue;
    payload.rmd = this.RMD_FRM.fields.rmd.value;
    if (this.fundingOption.rawValue === 'check' && !isEmpty(bankAccountStore.plaidAccDetails)) {
      payload.linkedBank = {};
      const {
        account_id,
        public_token,
        accountNumber,
        routingNumber,
      } = bankAccountStore.plaidAccDetails;
      if (account_id && public_token) {
        payload.linkedBank.plaidPublicToken = public_token;
        payload.linkedBank.plaidAccountId = account_id;
      } else {
        payload.linkedBank.accountNumber = accountNumber;
        payload.linkedBank.routingNumber = routingNumber;
      }
      const isValidAddFunds = bankAccountStore.formIraAddFunds.meta.isFieldValid;
      if (isValidAddFunds) {
        payload.initialDepositAmount = bankAccountStore.formIraAddFunds.fields.value.value;
      }
    } else if (this.fundingOption.rawValue === 'check') {
      payload.linkedBank = {};
      const { accountNumber, routingNumber } = bankAccountStore.formLinkBankManually.fields;
      if (accountNumber && routingNumber) {
        payload.linkedBank.accountNumber = accountNumber.value;
        payload.linkedBank.routingNumber = routingNumber.value;
        const isValidAddFunds = bankAccountStore.formIraAddFunds.meta.isFieldValid;
        if (isValidAddFunds) {
          payload.initialDepositAmount = bankAccountStore.formIraAddFunds.fields.value.value;
        }
      }
    }
    return payload;
  }

  @computed
  get isPrimary() {
    return this.beneficiaryType === 'PRIMARY';
  }

  @computed
  get isBeneficiaryValid() {
    return (this.isPrimaryBeneficiarySkipped || (this.isPrimaryBeneficiaryComplete && this.PRIMARY_BENEFICIARY_FRM.meta.isValid && this.isHundred(this.PRIMARY_BENEFICIARY_FRM)))
      && (this.isContingentBeneficiarySkipped || this.isContingentNotFilled || (this.isContingentBeneficiaryComplete && this.CONTINGENT_BENEFICIARY_FRM.meta.isValid && this.isHundred(this.CONTINGENT_BENEFICIARY_FRM)));
  }

  @computed get isContingentBeneficiarySkipped() {
    return get(this.beneficiaryDetails, 'contingentStatus') === 'skipped';
  }

  @computed get isContingentNotFilled() {
    return this.CONTINGENT_BENEFICIARY_FRM.fields.beneficiaries[0].name.value === '';
  }

  @computed get isPrimaryBeneficiarySkipped() {
    return get(this.beneficiaryDetails, 'primaryStatus') === 'skipped';
  }

  @computed get isContingentBeneficiaryComplete() {
    return get(this.beneficiaryDetails, 'contingentStatus') === 'complete';
  }

  @computed get isBeneficiarySkipped() {
    return this.isPrimaryBeneficiarySkipped && this.isContingentBeneficiarySkipped;
  }

  @computed get isPrimaryBeneficiaryComplete() {
    return get(this.beneficiaryDetails, 'primaryStatus') === 'complete';
  }

  @computed
  get isBeneficiaryComplete() {
    return this.isPrimaryBeneficiaryComplete && this.isContingentBeneficiaryComplete;
  }

  @computed
  get primaryStatus() {
    return this.isPrimaryBeneficiarySkipped ? 'skipped' : this.isPrimaryBeneficiaryComplete ? 'complete' : 'partial';
  }

  @computed
  get contingentStatus() {
    return this.isContingentBeneficiarySkipped ? 'skipped' : this.isContingentBeneficiaryComplete ? 'complete' : 'partial';
  }

  @action
  getAccountType= () => {
    const accountDetails = find(userDetailsStore.currentUser.data.user.roles, { name: 'ira' });
    const computedAccountType = get(accountDetails, 'details.iraAccountType');
    if (computedAccountType === 'roth') {
      this.setFieldValue('iraType', 'Roth');
    } else {
      this.setFieldValue('iraType', 'Traditional');
    }
  }

  isHundred = (form) => {
    const percentage = form.fields.beneficiaries
      .map(b => parseInt(b.percentage.value, 10))
      .reduce((a, b) => a + b, 0);
    return percentage === 100;
  }

  beneficiaryAttributes = (currentStep) => {
    const beneficiariesList = ['PRIMARY_BENEFICIARY_FRM', 'CONTINGENT_BENEFICIARY_FRM'].map(
      (f) => {
        const isContingentIncomplete = this.isPrimary && f === 'CONTINGENT_BENEFICIARY_FRM' && !this[f].meta.isValid;
        const isPrimarySkipped = f.startsWith('PRIMARY') && currentStep.primaryStatus === 'skipped';
        const isContingentSkipped = f.startsWith('CONTINGENT') && currentStep.contingentStatus === 'skipped';
        const isContingentNotFilled = f.startsWith('CONTINGENT') && this.isContingentNotFilled;
        if ((isPrimarySkipped || isContingentSkipped || isContingentIncomplete || isContingentNotFilled || currentStep.resetForms.includes(f))) {
          return [];
        }
        return FormValidator.evaluateFormData(this[f].fields).beneficiaries;
      },
    );
    const accountAttributes = {
      beneficiaries: beneficiariesList.map(b => b.map((c) => {
        // const isEmptyForm = Object.values(c).some(x => (x === null || x === ''));
        // eslint-disable-next-line no-param-reassign
        c.address = pick(c, ['street', 'city', 'state', 'streetTwo', 'zipCode']);
        return pick(c, ['name', 'relationship', 'address', 'taxId', 'dob', 'percentage', 'type']);
        // if (!isEmptyForm) {
        // }
        // return [];
      })).flat(1),
      primaryStatus: currentStep.primaryStatus,
      contingentStatus: currentStep.contingentStatus,
    };
    return accountAttributes;
  }

  @action
  isInvestorOverAge = () => {
    const birthDate = get(userDetailsStore, 'currentUser.data.user.legalDetails.dateOfBirth');
    const overAge = moment().diff(new Date(birthDate), 'years') >= 70.5;
    return overAge;
  }

  @action
  setNestedSteps = (step) => {
    this.setFieldValue('nestedSteps', [...Array(step).keys()]);
  }

  @action
  setAddressFieldsForProfile = (place, form) => {
    FormValidator.setAddressFields(place, this[form], true, US_STATES);
  }

  @action
  handleNestedStepChange = (backStepToBeRendered, increment = true) => {
    let steps = [];
    if (increment) {
      steps = [...toJS(this.nestedSteps), ...[this.currentNestedStep + 1]];
    } else {
      this.nestedSteps.pop();
      steps = this.nestedSteps;
    }
    if ((steps.length === 0 && backStepToBeRendered !== undefined)) {
      this.stepToBeRendered = backStepToBeRendered;
    }
    this.nestedSteps = steps;
  };

  @action
  setBeneficiaryDetails = (stepObj) => {
    const { primaryStatus, contingentStatus } = stepObj;
    this.beneficiaryDetails = { primaryStatus, contingentStatus };
  }

  @action
  handlebackForBeneficiaries = (backStepToBeRendered) => {
    const form = this.currentNestedStep > 2 ? 'CONTINGENT_BENEFICIARY_FRM' : 'PRIMARY_BENEFICIARY_FRM';
    if (this[form].fields.beneficiaries.length > 1 && !this.editBeneficiary) {
      const { beneficiaries } = FormValidator.evaluateFormData(this[form].fields);
      const filteredData = beneficiaries.filter(c => !Object.values(c).some(x => (x === null || x === '')));
      const data = filteredData.map((c) => {
        // eslint-disable-next-line no-param-reassign
        c.address = pick(c, ['street', 'city', 'state', 'streetTwo', 'zipCode']);
        return pick(c, ['name', 'relationship', 'address', 'taxId', 'dob', 'percentage', 'type']);
      });
      this.setArrayFormData(form, { beneficiaries: data });
    }
    if (this.currentNestedStep === 6) {
      this.setStepToBeRendered(this.stepToBeRendered - 1);
    } else {
      this.handleNestedStepChange(backStepToBeRendered, this.isBeneficiariesList);
    }
  }

  beneficiaryUpsert = async (isRemove = false) => {
    const stepObj = {
      name: 'Beneficiaries',
      stepToBeRendered: this.stepToBeRendered,
      resetForms: [],
      primaryStatus: this.isHundred(this.PRIMARY_BENEFICIARY_FRM) ? 'complete' : this.isPrimaryBeneficiarySkipped ? 'skipped' : 'partial',
      contingentStatus: this.isHundred(this.CONTINGENT_BENEFICIARY_FRM) ? 'complete' : this.isContingentBeneficiarySkipped ? 'skipped' : 'partial',
    };
    const res = await this.createAccount(stepObj);
    if (res) {
      this.setFieldValue('beneficiaryIndex', 0);
      this.setBeneficiaryDetails(stepObj);
      if (!isRemove) {
        return this.currentNestedStep === 6 ? this.setStepToBeRendered(this.stepToBeRendered + 1) : this.handleNestedStepChange();
      }
    }
    return false;
  }

  @action
  resetBeneficiariesData = (stepMeta, resetForm = true) => new Promise((resolve) => {
    let stepObj = {};
    const forms = this.isPrimary ? ['PRIMARY_BENEFICIARY_FRM', 'CONTINGENT_BENEFICIARY_FRM'] : ['CONTINGENT_BENEFICIARY_FRM'];
    stepObj = {
      name: 'Beneficiaries',
      isPrimary: this.isPrimary,
      stepToBeRendered: this.stepToBeRendered,
      primaryStatus: this.isPrimary ? 'skipped' : this.primaryStatus,
      contingentStatus: 'skipped',
    };
    if (resetForm) {
      stepObj.resetForms = forms;
    }
    if (stepMeta) {
      stepObj = { ...stepObj, ...stepMeta };
    }

    this.createAccount(stepObj).then((res) => {
      if (resetForm) {
        this.resetBeneficiaries(stepObj.resetForms);
      }
      this.setBeneficiaryDetails(stepObj);
      resolve(res);
    });
  })

  @action
  populateNestedSteps = () => {
    let steps = [];
    if (this.PRIMARY_BENEFICIARY_FRM.fields.beneficiaries[0].name.value === '') {
      steps.push(0);
    } else {
      steps = [...Array(7).keys()];
    }
    this.nestedSteps = [...steps];
  }

  @action
  setTypeForBeneficiaries = (form, index) => {
    this.TEMP_FRM.fields.beneficiaries[index].type.value = this.isPrimary ? 'PRIMARY' : 'CONTINGENT';
  }

  @action
  syncTempForm = (form) => {
    this.TEMP_FRM = { ...toJS(this[form]) };
  }

  @action
  submitAccount = () => new Promise((resolve) => {
    this.submitMutation().then(() => {
      resolve();
    }).catch((e) => {
      window.logger(e);
    });
  });

  @action
  submitMutation = () => new Promise((resolve, reject) => {
    const accountDetails = find(userDetailsStore.currentUser.data.user.roles, { name: 'ira' });
    uiStore.setProgress();
    const payLoad = {
      accountId: get(accountDetails, 'details.accountId') || this.iraAccountId,
      accountType: 'IRA',
    };
    client
      .mutate({
        mutation: submitInvestorAccount,
        variables: payLoad,
      })
      .then((res) => {
        if (Helper.matchRegexWithString(/\bprocessing(?![-])\b/, res.data.submitInvestorAccount)) {
          this.setFieldValue('showProcessingModal', true);
        }
        bankAccountStore.resetStoreData();
        this.isFormSubmitted = true;
        uiStore.setProgress(false);
        resolve();
      })
      .catch((err) => {
        if (Helper.matchRegexWithString(/\bNetwork(?![-])\b/, err.message)) {
          if (this.retry < 1) {
            this.retry += 1;
            this.submitAccount().then(() => uiStore.removeOneFromProgressArray('submitAccountLoader'));
          } else {
            uiStore.resetUIAccountCreationError(DataFormatter.getSimpleErr(err));
          }
        } else {
          uiStore.resetUIAccountCreationError(DataFormatter.getSimpleErr(err));
        }
        reject();
      });
  });

  @action
  setFieldValue = (field, value, objRef = false, isArray = false) => {
    if (isArray) {
      this[field] = this[field].concat(value);
    } else if (objRef) {
      const tempRef = this[field];
      this[field] = set(tempRef, objRef, value);
      this[field] = tempRef;
    } else {
      this[field] = value;
    }
    this.currTime = +new Date();
  }

  @action
  createAccount = (currentStep, removeUploadedData = false) => new Promise((resolve) => {
    if (!this.apiCall) {
      this.setFieldValue('apiCall', true);
      this.validateAndSubmitStep(currentStep, removeUploadedData).then((res) => {
        this.setFieldValue('apiCall', false);
        resolve(res);
      }).catch((e) => {
        this.setFieldValue('apiCall', false);
        window.logger(e);
      });
    }
  })

  @action
  validateForm = (form) => {
    if (Array.isArray(form)) {
      const multiForm = this.getActionType(form, 'isMultiForm');
      this[form] = FormValidator.validateForm(this[form[0]], multiForm, false, false);
    } else {
      this[form] = FormValidator.validateForm(this[form]);
    }
  }

  @action
  validateAndSubmitStep =
    (currentStep, removeUploadedData) => new Promise((res, rej) => {
      let isValidCurrentStep = true;
      const accountAttributes = {};
      switch (currentStep.name) {
        case 'About Ira':
          this.setStepToBeRendered(currentStep.stepToBeRendered);
          break;
        case 'Financial info':
          currentStep.validate('FIN_INFO_FRM');
          isValidCurrentStep = this.FIN_INFO_FRM.meta.isValid;
          if (isValidCurrentStep) {
            let limitValues = FormValidator.ExtractValues(this.FIN_INFO_FRM.fields);
            limitValues = omit(limitValues, ['investmentLimit']);
            accountAttributes.limits = limitValues;
            this.submitForm(currentStep, accountAttributes).then(() => {
              res();
            })
              .catch(() => {
                rej();
              });
          } else {
            rej();
          }
          break;
        case 'Account type':
          currentStep.validate('ACC_TYPES_FRM');
          accountAttributes.iraAccountType = this.accountType ? this.accountType.rawValue : '';
          isValidCurrentStep = this.ACC_TYPES_FRM.meta.isValid;
          if (isValidCurrentStep) {
            this.submitForm(currentStep, accountAttributes).then(() => {
              res();
            })
              .catch(() => {
                rej();
              });
          } else {
            rej();
          }
          break;
        case 'Beneficiaries':
          // eslint-disable-next-line no-case-declarations
          accountAttributes.beneficiary = this.beneficiaryAttributes(currentStep);
          this.submitForm(currentStep, accountAttributes)
            .then((result) => {
              res(result.data.upsertInvestorAccount);
            })
            .catch(() => {
              rej();
            });
          break;
        case 'Spousal Consent':
          currentStep.validate('SPOUSAL_CONSENT_FRM');
          accountAttributes.spousalConsent = this.spousalConsent ? this.spousalConsent.value : '';
          isValidCurrentStep = this.SPOUSAL_CONSENT_FRM.meta.isValid;
          if (isValidCurrentStep) {
            this.submitForm(currentStep, accountAttributes)
              .then(() => {
                res();
              })
              .catch(() => {
                rej();
              });
          }
          break;
        case 'Funding':
          currentStep.validate('FUNDING_FRM');
          accountAttributes.fundingType = this.fundingOption ? this.fundingOption.rawValue : '';
          isValidCurrentStep = this.FUNDING_FRM.meta.isValid;
          if (accountAttributes.fundingType !== 'check') {
            bankAccountStore.setPlaidAccDetails({});
            bankAccountStore.resetRoutingNum();
            bankAccountStore.setLinkBankSummary(false);
          }
          if (isValidCurrentStep) {
            this.submitForm(currentStep, accountAttributes).then(() => {
              res();
            })
              .catch((err) => {
                uiStore.setErrors(DataFormatter.getSimpleErr(err));
                uiStore.setProgress(false);
                rej();
              });
          } else {
            rej();
          }
          break;
        case 'Custodian':
          currentStep.validate('CUSTODIAN_FRM');
          isValidCurrentStep = this.CUSTODIAN_FRM.meta.isValid;
          if (isValidCurrentStep) {
            accountAttributes.custodian = this.custodianVariables;
            this.submitForm(currentStep, accountAttributes).then(() => {
              res();
            })
              .catch((err) => {
                uiStore.setErrors(DataFormatter.getSimpleErr(err));
                uiStore.setProgress(false);
                rej();
              });
          } else {
            rej();
          }
          break;
        case 'Rmd':
          currentStep.validate('RMD_FRM');
          accountAttributes.rmd = this.RMD_FRM && this.RMD_FRM.fields ? this.RMD_FRM.fields.rmd.value : '';
          isValidCurrentStep = this.RMD_FRM.meta.isValid;
          if (isValidCurrentStep) {
            this.submitForm(currentStep, accountAttributes)
              .then(() => {
                res();
              })
              .catch(() => {
                rej();
              });
          }
          break;
        case 'Link bank':
          if (bankAccountStore.manualLinkBankSubmitted) {
            currentStep.validate();
          }
          accountAttributes.linkedBank = bankAccountStore.accountAttributes.linkedBank;
          this.submitForm(currentStep, accountAttributes)
            .then(() => res()).catch(() => rej());
          break;
        case 'Add funds':
          if (parseFloat(bankAccountStore.formIraAddFunds.fields.value.value, 0) !== 0) {
            bankAccountStore.validateAddFunds();
          }
          isValidCurrentStep = bankAccountStore.formIraAddFunds.meta.isValid
            || bankAccountStore.isAccountPresent;
          if (isValidCurrentStep) {
            accountAttributes.initialDepositAmount = bankAccountStore.accountAttributes.initialDepositAmount;
            bankAccountStore.isValidOpeningDepositAmount(false).then(() => {
              this.submitForm(currentStep, accountAttributes)
                .then(() => res()).catch(() => rej());
            })
              .catch(() => {
                rej();
              });
          }
          break;
        case 'Assets Liquidation':
          currentStep.validate('ASSETS_LIQUIDATION_FRM');
          isValidCurrentStep = this.ASSETS_LIQUIDATION_FRM.meta.isValid;
          if (isValidCurrentStep) {
            accountAttributes.assetsLiquidation = this.assetsLiquidationVars;
            this.submitForm(currentStep, accountAttributes).then(() => {
              res();
            })
              .catch((err) => {
                uiStore.setErrors(DataFormatter.getSimpleErr(err));
                uiStore.setProgress(false);
                rej();
              });
          } else {
            rej();
          }
          break;
        case 'Identity':
          if (removeUploadedData) {
            accountAttributes.identityDoc = {
              fileId: '',
              fileName: '',
            };
            this.submitForm(currentStep, accountAttributes, removeUploadedData)
              .then(() => {
                res();
              })
              .catch(() => {
                rej();
              });
          } else {
            currentStep.validate('IDENTITY_FRM');
            isValidCurrentStep = this.IDENTITY_FRM.meta.isValid;
            if (isValidCurrentStep) {
              uiStore.setProgress();
              accountAttributes.identityDoc = {};
              accountAttributes.identityDoc.fileId = this.IDENTITY_FRM.fields.identityDoc.fileId;
              accountAttributes.identityDoc.fileName = this.IDENTITY_FRM.fields.identityDoc.value;
              this.submitForm(currentStep, accountAttributes).then(() => {
                res();
              })
                .catch((err) => {
                  uiStore.setErrors(DataFormatter.getSimpleErr(err));
                  uiStore.setProgress(false);
                  rej();
                });
            } else {
              rej();
            }
          }
          break;
        case 'IraStatement':
          if (removeUploadedData) {
            accountAttributes.uploads = {
              fileId: '',
              fileName: '',
              type: '',
            };
            this.submitForm(currentStep, accountAttributes, removeUploadedData)
              .then(() => {
                res();
              })
              .catch(() => {
                rej();
              });
          } else {
            currentStep.validate('IRA_STATEMENT_UPLOAD_FRM');
            isValidCurrentStep = this.IRA_STATEMENT_UPLOAD_FRM.meta.isValid;
            if (isValidCurrentStep) {
              uiStore.setProgress();
              const tempData = {};
              tempData.fileId = this.IRA_STATEMENT_UPLOAD_FRM.fields.uploads.fileId;
              tempData.fileName = this.IRA_STATEMENT_UPLOAD_FRM.fields.uploads.value;
              tempData.type = this.IRA_STATEMENT_UPLOAD_FRM.fields.uploads.type;
              accountAttributes.uploads = [tempData];
              this.submitForm(currentStep, accountAttributes).then(() => {
                res();
              })
                .catch((err) => {
                  uiStore.setErrors(DataFormatter.getSimpleErr(err));
                  uiStore.setProgress(false);
                  rej();
                });
            } else {
              rej();
            }
          }
          break;
        default:
          break;
      }
      return true;
    })

  @action
  submitForm = (currentStep, accountAttributes, removeUploadedData = false) => {
    uiStore.setProgress();
    let mutation = upsertInvestorAccount;
    const variables = {
      accountAttributes,
      accountType: 'IRA',
    };
    let actionPerformed = 'submitted';
    if (userDetailsStore.currentUser.data) {
      const accountDetails = find(userDetailsStore.currentUser.data.user.roles, { name: 'ira' });
      if (accountDetails || this.iraAccountId) {
        mutation = upsertInvestorAccount;
        variables.accountId = get(accountDetails, 'details.accountId')
          || this.iraAccountId;
        actionPerformed = 'updated';
      }
    }
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables,
        })
        .then(action((result) => {
          this.iraAccountId = result.data.upsertInvestorAccount.accountId;
          if (!isMobile) {
            accountStore.accountToastMessage(currentStep, actionPerformed, 'formIraAddFunds');
          }
          const isBankSteps = ['Link bank', 'Add funds'].includes(currentStep.name);
          if (result.data.upsertInvestorAccount && isBankSteps) {
            const { linkedBank } = result.data.upsertInvestorAccount;
            bankAccountStore.setPlaidAccDetails(linkedBank);
          }
          if (currentStep.name === 'Identity') {
            if (removeUploadedData) {
              currentStep.validate('IDENTITY_FRM');
            } else if (currentStep.form) {
              FormValidator.setIsDirty(this[currentStep.form], false);
            }
          } else if (currentStep.name === 'IraStatement') {
            if (removeUploadedData) {
              currentStep.validate('IRA_STATEMENT_UPLOAD_FRM');
            } else {
              FormValidator.setIsDirty(this[currentStep.form], false);
            }
          } else if (!isBankSteps && currentStep.form) {
            FormValidator.setIsDirty(this[currentStep.form], false);
          }
          this.setStepToBeRendered(currentStep.stepToBeRendered);
          uiStore.setErrors(null);
          uiStore.setProgress(false);
          resolve(result);
        }))
        .catch((err) => {
          if (currentStep.name === 'Link bank') {
            bankAccountStore.resetShowAddFunds();
            bankAccountStore.setPlaidAccDetails({});
          }
          uiStore.setErrors(DataFormatter.getSimpleErr(err));
          uiStore.setProgress(false);
          reject(err);
        });
    });
  }

  @action
  getActionType = (formName, getField = 'actionType') => {
    const metaDataMapping = {
      [formName]: { isMultiForm: true },
    };
    return metaDataMapping[formName][getField];
  }

  @action
  setArrayFormData = (form, data) => {
    this[form] = FormValidator.setFormData(this[form], data);
    const multiForm = this.getActionType(form, 'isMultiForm');
    this[form] = FormValidator.validateForm(this[form], multiForm, false, false);
  }

  beneficiaryMetaData = ({ primary, contingent }) => [
    { form: 'PRIMARY_BENEFICIARY_FRM', data: primary, isSkipped: this.isPrimaryBeneficiarySkipped },
    { form: 'CONTINGENT_BENEFICIARY_FRM', data: contingent, isSkipped: this.isContingentBeneficiarySkipped },
  ]

  @action
  populateData = (userData) => {
    if (Helper.matchRegexWithUrl([/\bira(?![-])\b/]) && !bankAccountStore.bankSelect) {
      if (!isEmpty(userData)) {
        const account = find(userData.roles, { name: 'ira' });
        if (account) {
          this.setFormData('FIN_INFO_FRM', account.details, userData);
          this.setFormData('FUNDING_FRM', account.details);
          this.setFormData('SPOUSAL_CONSENT_FRM', account.details);
          this.setFormData('ACC_TYPES_FRM', account.details);
          this.setFormData('IDENTITY_FRM', account.details);
          this.setFormData('CUSTODIAN_FRM', account.details);
          const uploads = get(account, 'details.uploads') && account.details.uploads.find(b => b.type === 'iraStatement');
          this.setFormData('IRA_STATEMENT_UPLOAD_FRM', { uploads });
          if (get(account, 'details.rmd')) {
            this.setFormData('RMD_FRM', account.details);
          }
          const primary = (get(account, 'details.beneficiary.beneficiaries') && account.details.beneficiary.beneficiaries.filter(b => b.type === 'PRIMARY')) || [];
          const contingent = (get(account, 'details.beneficiary.beneficiaries') && account.details.beneficiary.beneficiaries.filter(b => b.type === 'CONTINGENT')) || [];
          this.beneficiaryMetaData({ primary, contingent }).forEach((obj) => {
            const key = obj.form.startsWith('PRIMARY') ? 'primaryStatus' : 'contingentStatus';
            this.beneficiaryDetails[key] = get(account, `details.beneficiary.${key}`);
            if (this[key] !== 'skipped') {
              this.setArrayFormData(obj.form, { beneficiaries: obj.data });
            }
          });
          this.setFormData('ASSETS_LIQUIDATION_FRM', account.details);
          this.setArrayFormData('ASSETS_FRM', { assets: (get(account, 'details.assetsLiquidation.assets')) });
          if (account.details.linkedBank) {
            const plaidAccDetails = account.details.linkedBank;
            if (!bankAccountStore.isAccountPresent) {
              bankAccountStore.setPlaidAccDetails(plaidAccDetails);
            }
            bankAccountStore.formIraAddFunds.fields.value.value = account.details.initialDepositAmount;
          } else {
            Object.keys(bankAccountStore.formLinkBankManually.fields).map((f) => {
              const { details } = account;
              if (details.linkedBank && details.linkedBank[f] !== '') {
                bankAccountStore.formLinkBankManually.fields[f].value = details.linkedBank[f];
                return bankAccountStore.formLinkBankManually.fields[f];
              }
              return null;
            });
            if (account.details.linkedBank && account.details.linkedBank.routingNumber !== ''
              && account.details.linkedBank.accountNumber !== '') {
              bankAccountStore.linkBankFormChange();
            }
            bankAccountStore.formIraAddFunds.fields.value.value = account.details.initialDepositAmount;
          }
          bankAccountStore.validateAddFunds();
          bankAccountStore.validateAddfundsAmount();
          const { fundingType } = this.FUNDING_FRM.fields;
          const getIraStep = AccCreationHelper.iraSteps(fundingType.value);
          if (!this.FIN_INFO_FRM.meta.isValid) {
            this.setStepToBeRendered(getIraStep.FIN_INFO_FRM);
          } else if (!this.ACC_TYPES_FRM.meta.isValid) {
            this.setStepToBeRendered(getIraStep.ACC_TYPES_FRM);
          } else if (!this.IDENTITY_FRM.meta.isValid) {
            this.setStepToBeRendered(getIraStep.IDENTITY_FRM);
          } else if (!this.isBeneficiaryValid) {
            this.setStepToBeRendered(getIraStep.BENEFICIARY_FRM);
          } else if (!this.SPOUSAL_CONSENT_FRM.meta.isValid) {
            this.setStepToBeRendered(getIraStep.SPOUSAL_CONSENT_FRM);
          } else if (!this.FUNDING_FRM.meta.isValid) {
            this.setStepToBeRendered(getIraStep.FUNDING_FRM);
          } else if (fundingType.value !== 0) {
            if (!this.CUSTODIAN_FRM.meta.isValid) {
              this.setStepToBeRendered(getIraStep.CUSTODIAN_FRM);
            } else if (!this.RMD_FRM.meta.isValid && this.isInvestorOverAge()) {
              this.setStepToBeRendered(getIraStep.RMD_FRM);
            } else if (!this.ASSETS_LIQUIDATION_FRM.meta.isValid) {
              this.setStepToBeRendered(getIraStep.ASSETS_LIQUIDATION_FRM);
            } else if (!this.isValidIraDocusign && this.isValidIraForm) {
              this.setStepToBeRendered(getIraStep.SUMMARY);
            } else if (this.showProcessingModal) {
              this.setStepToBeRendered(getIraStep.THANK_YOU);
            } else {
              this.setStepToBeRendered(getIraStep.SIGNATURES);
            }
          } else if (fundingType.value === 0) {
            if (bankAccountStore.isLinkbankInComplete) {
              this.setStepToBeRendered(getIraStep.LINK_BANK);
            } else if (bankAccountStore.isAccountPresent
            && !bankAccountStore.formIraAddFunds.meta.isValid) {
              this.setStepToBeRendered(getIraStep.ADD_FUNDS);
            } else if (!this.IRA_STATEMENT_UPLOAD_FRM.meta.isValid) {
              this.setStepToBeRendered(getIraStep.IRA_STATEMENT_UPLOAD_FRM);
            } else if (!this.isValidIraDocusign && this.isValidIraForm) {
              this.setStepToBeRendered(getIraStep.SUMMARY);
            } else if (this.showProcessingModal) {
              this.setStepToBeRendered(getIraStep.THANK_YOU);
            } else {
              this.setStepToBeRendered(getIraStep.SIGNATURES);
            }
          }
        }
      }
    }
    uiStore.setProgress(false);
  }

  @computed
  get isThankYouStep() {
    const fundingType = this.fundingOption ? this.fundingOption.value : '';
    return ((this.stepToBeRendered === 11 && fundingType === 0) || (this.stepToBeRendered === 13 && fundingType !== 0));
  }

  @action
  setFormData = (form, accountDetails, userData) => {
    Object.keys(this[form].fields).map((f) => {
      if (form === 'FIN_INFO_FRM' && f !== 'investmentLimit' && userData && userData.limits) {
        this[form].fields[f].value = userData.limits[f];
      } else if (form === 'IDENTITY_FRM') {
        if (accountDetails[f]) {
          this.IDENTITY_FRM.fields[f].value = accountDetails[f].fileName;
          this.IDENTITY_FRM.fields[f].fileId = accountDetails[f].fileId;
        }
      } else if (form === 'IRA_STATEMENT_UPLOAD_FRM') {
        if (accountDetails[f]) {
          this.IRA_STATEMENT_UPLOAD_FRM.fields[f].value = accountDetails[f].fileName;
          this.IRA_STATEMENT_UPLOAD_FRM.fields[f].fileId = accountDetails[f].fileId;
          this.IRA_STATEMENT_UPLOAD_FRM.fields[f].type = accountDetails[f].type;
        }
      } else if ((form === 'FUNDING_FRM' || form === 'ACC_TYPES_FRM') && accountDetails && accountDetails[f]) {
        let value = '';
        if (form === 'FUNDING_FRM') {
          value = AccCreationHelper.getFundingTypeIndex(accountDetails[f]);
        } else {
          value = AccCreationHelper.getAccountTypeIndex(accountDetails[f]);
        }
        if (value !== '') {
          this[form].fields[f].value = value;
        } else {
          this[form].fields[f].value = '';
        }
      } else if ((form === 'SPOUSAL_CONSENT_FRM' || form === 'RMD_FRM')
        && accountDetails && (accountDetails[f] || form === 'SPOUSAL_CONSENT_FRM')) {
        let fieldValue = '';
        fieldValue = accountDetails[f];
        if (fieldValue !== '') {
          this[form].fields[f].value = fieldValue;
        } else {
          this[form].fields[f].value = '';
        }
      } else if (form === 'CUSTODIAN_FRM' && accountDetails.custodian) {
        if (accountDetails.custodian.address[f]) {
          this[form].fields[f].value = accountDetails.custodian.address[f];
        } else if (f === 'other' && userDetailsStore.isOtherPlanType) {
          this[form].fields[f].value = accountDetails.custodian.planType;
        } else {
          this[form].fields[f].value = accountDetails.custodian[f];
        }
      } else if (form === 'ASSETS_LIQUIDATION_FRM' && accountDetails.assetsLiquidation) {
        this[form].fields[f].value = accountDetails.assetsLiquidation[f];
      }
      return this[form].fields[f];
    });
    FormValidator.onChange(this[form], '', '', false);
  }

  @action
  setFileUploadData = (field, form, files, callApi = true) => {
    const docForm = form;
    uiStore.setProgress();
    const file = files[0];
    const stepName = FILE_UPLOAD_STEPS[field];
    const fileData = Helper.getFormattedFileData(file);
    fileUpload.setFileUploadData('', fileData, stepName, 'INVESTOR').then(action((result) => {
      const { fileId, preSignedUrl } = result.data.createUploadEntry;
      this[form].fields[field].fileId = fileId;
      this[form].fields[field].preSignedUrl = preSignedUrl;
      this[form].fields[field].fileData = file;
      this[form].fields[field].type = form === 'IDENTITY_FRM' ? 'Identity' : 'iraStatement';
      this[form] = FormValidator.onChange(
        this[form],
        { name: field, value: fileData.fileName },
      );
      uiStore.setProgress();
      fileUpload.putUploadedFileOnS3({ preSignedUrl, fileData: file, fileType: fileData.fileType })
        .then(() => {
          const currentStep = {
            name: form === 'IDENTITY_FRM' ? 'Identity' : 'IraStatement',
            validate: validationActions.validateIRAForm,
            form: docForm,
            stepToBeRendered: form === 'IDENTITY_FRM' ? 4 : 11,
          };
          if (callApi) {
            this.createAccount(currentStep, false);
          } else {
            uiStore.setProgress(false);
          }
        })
        .catch((err) => {
          Helper.toast('Something went wrong, please try again later.', 'error');
          uiStore.setProgress(false);
          uiStore.setErrors(DataFormatter.getSimpleErr(err));
        });
    }));
  }

  @action
  removeUploadedData = (field, form) => {
    const currentStep = { name: 'Identity', validate: validationActions.validateIRAForm };
    const { fileId } = this[form].fields[field];
    fileUpload.removeUploadedData(fileId).then(action(() => {
      this[form].fields[field].value = '';
      this[form].fields[field].fileId = '';
      this[form].fields[field].type = '';
      this[form].fields[field].preSignedUrl = '';
      this.createAccount(currentStep, true);
    }))
      .catch(() => { });
  }

  @action
  resetFormData(form) {
    const resettedForm = FormValidator.resetFormData(this[form]);
    this[form] = resettedForm;
  }

  @action
  addMoreAssets = (e = null, formName) => {
    if (e) {
      e.preventDefault();
    }
    this.ASSETS_FRM = {
      ...this.ASSETS_FRM,
      fields: {
        ...this.ASSETS_FRM.fields,
        [formName]: [
          ...this.ASSETS_FRM.fields[formName],
          ASSETS_LIST[formName][0],
        ],
      },
      meta: {
        ...this.ASSETS_FRM.meta,
        isValid: false,
      },
    };
  }

  @action
  resetBeneficiaries = (forms = false) => {
    // this.resetFormData('DESIGNATE_BENEFICIARY_FRM');
    if (forms) {
      forms.forEach(f => this.resetFormData(f));
    } else {
      this.resetFormData('PRIMARY_BENEFICIARY_FRM');
      this.resetFormData('CONTINGENT_BENEFICIARY_FRM');
    }
    if (this.isPrimary) {
      this.nestedSteps = [0];
    }
    this.beneficiaryIndex = 0;
    this.editBeneficiary = false;
  }

  @action
  signIraApplication = () => {
    const accountDetails = find(userDetailsStore.currentUser.data.user.roles, { name: 'ira' });
    uiStore.setProgress(true);
    const payLoad = {
      accountId: get(accountDetails, 'details.accountId') || this.iraAccountId,
      callBackUrl: `${window.location.origin}/secure-gateway`,
    };
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: generateIraAccountApplication,
          variables: payLoad,
        })
        .then((result) => {
          this.setFieldValue('signIraApplicationURL', result.data.generateIraAccountApplication);
          resolve(result);
        })
        .catch((error) => {
          uiStore.setErrors(error.message);
          if (Helper.matchRegexWithString(/\bsigned(?![-])\b/, error.message)) {
            this.submitAccount();
          } else reject(error);
        })
        .finally(() => {
          uiStore.setProgress(false);
        });
    });
  }

  @action
  resetDocusignUrl = () => {
    this.signIraApplicationURL = '';
  }

  @action
  resetStoreData = () => {
    this.resetFormData('FIN_INFO_FRM');
    this.resetFormData('IDENTITY_FRM');
    this.resetFormData('ACC_TYPES_FRM');
    this.resetFormData('FUNDING_FRM');
    this.resetFormData('SPOUSAL_CONSENT_FRM');
    this.resetFormData('RMD_FRM');
    this.resetFormData('CUSTODIAN_FRM');
    this.resetFormData('ASSETS_LIQUIDATION_FRM');
    this.resetFormData('ASSETS_FRM');
    this.resetFormData('IRA_STATEMENT_UPLOAD_FRM');
    this.resetBeneficiaries();
    this.stepToBeRendered = 0;
    this.accountNotSet = '';
    this.iraAccountId = null;
    this.isFormSubmitted = false;
    this.retry = 0;
  }
}
export default new IraAccountStore();
