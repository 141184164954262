import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Header, Container, Button, Dimmer, Loader, Grid } from 'semantic-ui-react';
import NSImage from '../../../shared/NSImage';

@inject('uiStore', 'publicStore')
@withRouter
@observer
class Banner extends Component {
  render() {
    const { showButton, redirectUrl } = this.props.publicStore;
    const { responsiveVars } = this.props.uiStore;
    const { uptoTablet } = responsiveVars;
    return (
      <section className="banner business-banner bg-white">
        <Container>
          <Grid>
            <Grid.Column widescreen={7} computer={7} tablet={16} mobile={16} stackable>
              <div className="banner-caption">
              {uptoTablet && (
                <>
                  <NSImage className="mt-20" NSImage path="banners/home-banner.png" />
                </>
              )}
              <Header className="home-header" as="h2">
                Meaningful investments in<br />small businesses<br />&amp; commercial real estate
              </Header>
              <p>Browse highly vetted investments in<br />communities all over the US.</p>
              {showButton
                ? (
                  <Button
                    className={`${responsiveVars.isMobile && 'mt-10'} relaxed`}
                    primary
                    content="Get Started"
                    as={Link}
                    to={redirectUrl}
                    fluid={responsiveVars.isMobile}
                  />
                ) : ''
              }
              </div>
            </Grid.Column>
            <Grid.Column widescreen={9} computer={9} tablet={16} mobile={16}>
            {!uptoTablet
            && (
              <>
                <NSImage path="banners/home-banner.png" />
              </>
            )}
            </Grid.Column>
          </Grid>
          {/* <div className="banner-meta">
            <p>
              Bravery Chef Hall<br /><b>Raised $1,000,000 from 539  investors</b>
            </p>
          </div> */}
        </Container>
        {this.props.withDimmer && (
          <Dimmer active className="fullscreen">
            <Loader active>Loading..</Loader>
          </Dimmer>
        )}
      </section>
    );
  }
}

export default Banner;
